import React from "react";

const SocialMediaLinks: React.FC = () => {
  return (
    <div className="flex flex-row gap-2.5 justify-center">
      <a href="https://github.com/cglezar" target="_blank" rel="noreferrer">
        <i className="icon-github transition delay-200 text-4xl hover:text-5xl"></i>
      </a>
      <a
        href="https://www.linkedin.com/in/cglezar"
        target="_blank"
        rel="noreferrer"
      >
        <i className="icon-linkedin transition delay-200 text-4xl hover:text-5xl"></i>
      </a>
      <a href="https://twitter.com/cglezar" target="_blank" rel="noreferrer">
        <i className="icon-twitter transition delay-200 text-4xl hover:text-5xl"></i>
      </a>
    </div>
  );
};

export default SocialMediaLinks;
