import React from "react";
import profilePicture from "./profile-picture.jpg";
import SocialMediaLinks from "../SocialMediaLinks/SocialMediaILinks";

function Homepage() {
  return (
    <div className="flex flex-col gap-24 mx-[10%] my-24">
      <p className="font-normal text-xl uppercase text-center">
        Cesar A. Gonzalez Argandar
      </p>
      <div className="summary__container bg-white flex flex-wrap flex-row justify-center lg:items-center md:flex-nowrap">
        <img
          src={profilePicture}
          alt="profile"
          className="summary__profile-picture min-w-[240px] max-h-[unset] md:min-w-[unset] md:max-h-[450px] lg:max-h-[unset] lg:min-w-[50%]"
        />
        <div className="summary__bio text-base/loose text-gray-400 mx-10 md:mx-10 md:text-[1.3vw] lg:mx-18">
          <h1 className="text-black mt-2.5 text-xl lg:text-2xl font-semibold">
            Web developer
          </h1>
          <p className="my-5">
            Hello. My name is Cesar. I live in Seattle, Washington. I am a
            software developer engineer focused on front-end technologies. I
            have spent most of my career working in web development. I began my
            journey working in very interesting projects in the Aviation
            business as part of General Electric. Then, I stared working for one
            of Expedia Group’s (EG) most unique travel sites, Hotwire.
          </p>
          <p className="my-5">
            Currently, I work building the lodging experience for EG’s main
            website expedia.com
          </p>
          <p className="my-5">
            I like spending my time outdoors in the beautiful PNW, traveling and
            dancing salsa.
          </p>
        </div>
      </div>
      <SocialMediaLinks />
    </div>
  );
}

export default Homepage;
